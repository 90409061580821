
<template>
	<div>
	<div class="upperWrap">
		<!-- // headerwrap -->
		<!-- visual wrap-->
		<div class="visualWrap">
			<div class="innerWrap">
				<h1 class="">관리업무의 효율을 높여주는<p class="b">공공주택 업무 솔루션</p></h1>
				<ul class="mt20">
					<li>
						<div class="imgWrap">
							<img src="img/n_main/icon01.png" alt="아이콘">
						</div>
						<p class=" b">세대민원<br>관리</p>
					</li>
					<li>
						<div class="imgWrap">
							<img src="img/n_main/icon02.png" alt="아이콘">
						</div>
						<p class=" b">간편한<br>안내방송</p>
					</li>
					<li>
						<div class="imgWrap">
							<img src="img/n_main/icon03.png" alt="아이콘">
						</div>
						<p class=" b">전자결재<br>일일보고</p>
					</li>
					<li>
						<div class="imgWrap">
							<img src="img/n_main/icon04.png" alt="아이콘">
						</div>
						<p class=" b">장기수선계획<br>검토·조정 용이</p>
					</li>
					<li>
						<div class="imgWrap">
							<img src="img/n_main/icon05.png" alt="아이콘">
						</div>
						<p class=" b">각종 전자문서<br>영구 보관</p>
					</li>
				</ul>
			</div>
		</div>
		<!-- // visualwrap -->
	</div>
	<!-- // upperwrap -->
	<!-- funcWrap -->
	<div class="funcWrap">
		<div class="innerWrap">
			<div class="Info">
				<p class="pointC more">SMART SYSTEM</p>
				<h2><span>아파트 관리 업무</span>를 <br>위한 <span>다양한 기능</span>이 <br>제공됩니다</h2>
				 <a href="javascript:;" @click="moveTo('A')" class="btn btnBasic bolder type02">주요기능 살펴보기</a>
			</div>
		</div>
	</div>
	<!-- // funcWrap -->
	<!-- meritWrap-->
	<div class="meritWrap">
		<div class="innerWrap">
			<h2>aptok 특장점</h2>
			<div class="meritBox">
				<p class="numTitle">01</p>
				<p class="sectTitle pb20"><span class="pointC">K-apt, S-apt 연동</span> 통한 업무 효율 증가</p>
				<p class="">연동을 통한 다양한 기능을 사용하실 수 있으며 <br>프로그램 기초세팅에 드는 시간을 줄이고<br>입주민들을 체계적으로 관리하실 수 있습니다. </p>
				<div class="imgWrap02">
					<img src="img/n_main/mIcon1.png" alt="아이콘">
				</div>
			</div>
			<div class="meritBox">
				<p class="numTitle">02</p>
				<p class="sectTitle pb20"><span class="pointC">아파트 관리 직원을 고려한</span> 직관적인 인터페이스</p>
				<p class="">복잡한 메뉴얼을 읽어보지 않아도 바로바로 사용 가능하도록, 업무 카테고리별로 묶어 정리한 <br>편리하고 편안한 사용자 중심 시스템입니다. </p>
				<div class="imgWrap02">
					<img src="img/n_main/mIcon2.png" alt="아이콘">
				</div>
			</div>
			<div class="meritBox">
				<p class="numTitle">03</p>
				<p class="sectTitle pb20">다양한 문서의 <span class="pointC">빠른 검색 및 안심 보관</span></p>
				<p class="">공동주택 관리문서를 유형별로 표준화하여, 제목만으로 쉽게 찾는 것은 물론, <br>상세한 검색 기능을 제공하여 오래된 문서의 경우도 쉽게 찾아낼 수 있습니다.<br>아파트의 여러 법적 문서들을 분실·훼손되지 않는 전자문서로 영구보존하세요.</p>
				<div class="imgWrap02">
					<img src="img/n_main/mIcon3.png" alt="아이콘">
				</div>
			</div>
			<div class="meritBox">
				<p class="numTitle">04</p>
				<p class="sectTitle pb20"> <span class="pointC">시설이력관리</span>와 <span class="pointC">장기수선계획 </span>검토·조정 용이</p>
				<p class="">관리 담당자나 업체가 바뀌더라도, 수선내역과 이력은 연속성을 가지고 관리하여 <br>효율적인 관리가 가능하며 비용 추적이 용이합니다.</p>
				<div class="imgWrap02">
					<img src="img/n_main/mIcon4.png" alt="아이콘">
				</div>
			</div>
			<div class="meritBox">
				<p class="numTitle">05</p>
				<p class="sectTitle pb20"><span class="pointC">교육, 안전, 행정</span> 업무 도우미</p>
				<p class="">법정검사, 법정교육, 안전점검, 행정관리 월별 달력을 제공합니다.  <br>매달 해야 하는 법적인 업무를 놓치지 않고 진행하여 과태료 예방 효과가 있으며,  <br> 일간/주간/월간 캘린더를 통해 전직원이 업무일정을 공유할 수 있습니다.</p>
				<div class="imgWrap02">
					<img src="img/n_main/mIcon5.png" alt="아이콘">
				</div>
			</div>
		</div>
	</div>
	<!--// meritWrap-->

	<!-- priceWrap -->
	<div class="meritWrap" style="background:#f5f5f5;">
		<div class="innerWrap">
			<h2>가격안내</h2>
			<div style="overflow-x:auto;">
				<table cellpadding="0" cellspacing="0" summary="" class="priceTable" style="min-width:430px;">
				<caption></caption>
				<colgroup>
				<col style="width:19%;">
				<col style="width:27%;">
				<col style="width:27%;">
				<col style="width:27%;">
				</colgroup>
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">서비스 이용료(기본)</th>
						<th scope="col">서비스 이용료(라이트)</th>
						<th scope="col">서비스 이용료(프로)</th>
					</tr>
				</thead>
				<tbody>
					<tr>
					<th>기준</th>
					<td class="ac">0 ~ 400세대</td>
					<td class="ac">401 ~ 1000세대</td>
					<td class="ac">1001 ~ 3000세대</td>
					</tr>
					<tr>
					<th>월비용<span class="sm">(VAT 별도)</span></th>
					<td class="ar number">60,000원</td>
					<td class="ar number">80,000원</td>
					<td class="ar number">120,000원</td>
					</tr>
					<tr>
					<th>연간비용<span class="sm">(VAT 별도)</span></th>
					<td class="ar number">720,000원</td>
					<td class="ar number">960,000원</td>
					<td class="ar number">1,440,000원</td>
					</tr>
					<tr class="sum">
					<th>연간합계(VAT포함)</th>
					<td class="ar number">792,000원</td>
					<td class="ar number">1,056,000원</td>
					<td class="ar number">1,584,000원</td>
					</tr>
				</tbody>
				</table>
			</div>
			<p class="al mt10">※ 협의에 따라 금액이 조정될 수 있습니다.</p>
			<!-- //aptok -->
		</div>
	  </div>
	<!-- //priceWrap -->


	<!--full Wrap-->
	<div class="fullWrap">
		<div class="leftWrap">
			<h3>이용상담 및 <br>계약문의</h3>
			<p class="tel pt40"><i class="fas fa-phone mr20"></i>02-858-3001</p>
			<p class="mail"><i class="fas fa-envelope mr20"></i>support@aprocni.com</p>
			<a href="javascript:;" @click="moveTo('B')" class="btn btnBasic bolder type02">무료 상담 문의</a>
		</div>
		<div class="rightWrap right01">
			<h3>무료로<br>체험해보세요</h3>
			<p>도입 전 미리 이용해 보세요</p>
			<a href="javascript:;" @click="moveTo('B')" class="btn btnBasic bolder">체험신청</a>
		</div>
		<div class="rightWrap right02">
			<h3>매뉴얼 및<br>이용지원</h3>
			<p>이용중 궁금한 사항이 <br>생기셨나요?</p>
			<a href="javascript:;" @click="moveTo('C')" class="btn btnBasic bolder ">바로가기</a>
		</div>
	</div>
	</div>
</template>

<script>
import { reactive } from '@vue/runtime-core';
import { useRouter } from 'vue-router';


export default {
  name: 'Home',
  props: {
    msg: String
  },

  setup(){
	  	const router = useRouter();

		const menu = reactive([
			{type : 'A', url : '/merit'},
			{type : 'B', url : '/inquiry'},
			{type : 'C', url : '/support'},
			{type : 'D', url : '/login'},
			{type : 'E', url : '/'},
		]);

	  const moveTo = (type) => {
		const url = menu.filter(item => item.type == type).map(item2 => item2.url)[0];
		
		if(url){

			if(url == '/login'){
				location.href = "https://aptok.co.kr";

			}else{
				router.push({path : url});
			}
		}
		
	};

	return {moveTo};
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
